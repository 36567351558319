import React, { FC } from "react";

export const Services:FC = () => (
  <section id="services" className="services section-bg">
    <div className="container pt-5">

      <div className="section-title" data-aos="fade-up">
        <h2>Services</h2>
        <p>Our services can be customized and tailored to meet your specific requirements and objectives. Our team is dedicated to delivering comprehensive solutions that drive app success and ensure a positive return on your investment.</p>
      </div>

      <div className="row">
        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in">
          <div className="icon-box icon-box-pink">
          <div className="icon"><i className="bx bx-world"></i></div>
            <h4 className="title">
              <a href="">Mobile App User Acquisition</a>
            </h4>
            <p className="description">Our agency specializes in driving targeted user acquisition for mobile apps. We leverage a combination of data-driven strategies, advanced targeting techniques, and engaging ad creatives to attract high-quality users and maximize app installs.</p>
          </div>
        </div>

        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="100">
          <div className="icon-box icon-box-cyan">
            <div className="icon"><i className="bx bx-tachometer"></i></div>
            <h4 className="title"><a href="">Performance Campaign Optimization</a></h4>
            <p className="description">We offer comprehensive performance campaign optimization services to ensure your mobile promotions deliver the best possible results. Our team continuously monitors campaign performance, analyzes data, and fine-tunes targeting parameters, ad formats, and bidding strategies to optimize conversions, improve ROI, and drive sustainable growth.</p>
          </div>
        </div>

        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
          <div className="icon-box icon-box-green">
            <div className="icon"><i className="bx bxl-dribbble"></i></div>  
            <h4 className="title"><a href="">App Store Optimization (ASO)</a></h4>
            <p className="description">Our ASO services help enhance your app's visibility and organic discovery in app stores. We conduct in-depth keyword research, optimize app metadata, improve ratings and reviews, and implement visual enhancements to boost your app's ranking, increase organic installs, and maximize its overall visibility among relevant target audiences.</p>
          </div>
        </div>

        <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
          <div className="icon-box icon-box-blue">
            <div className="icon"><i className="bx bx-file"></i></div>
            <h4 className="title"><a href="">Analytics and Reporting</a></h4>
            <p className="description">We provide robust analytics and reporting solutions to give you valuable insights into your mobile promotion campaigns. Our team leverages advanced tracking and analytics tools to measure key performance indicators, such as installs, in-app actions, revenue, and user engagement. These insights help you make data-driven decisions, optimize campaigns, and achieve your business goals.</p>
          </div>
        </div>

      </div>

    </div>
  </section>
)
import React, { FC, MouseEvent } from "react"
import { LinkedIn } from "./LinkedIn";
import { scrollTo } from "../helper";

const isGradientt = process.env.REACT_APP_NAME === 'Gradientt'

export const Header:FC = () => (
  <header id="header" className="fixed-top d-flex align-items-center">
    <div className="container d-flex align-items-center">

      <div className="logo me-auto">
        {isGradientt ? 
        <a onClick={e => scrollTo(e, 'root')}>
        <img src="media/gradientt-logo.svg" alt="" className="img-fluid" />
      </a>:
      <h1><a onClick={e => scrollTo(e, 'root')}>MobArt</a></h1>}
      </div>

      <nav id="navbar" className="navbar order-last order-lg-0">
        <ul>
          {/* <li><a className="nav-link scrollto active" href="#hero">Home</a></li> */}
          {/* <li className="dropdown">
            <a onClick={e => handleClick(e, 'about')}>
              <span>About</span> 
              <i className="bi bi-chevron-down"></i>
            </a>
            <ul>
              <li><a className="nav-link" onClick={e => handleClick(e, 'about')}>About Us</a></li>
              <li><a className="nav-link" onClick={e => handleClick(e, 'testimonials')}>Testimonials</a></li>
              <li><a className="nav-link" onClick={e => handleClick(e, 'team')}>Team</a></li>
              <li className="dropdown"><a href="#"><span>Deep Drop Down</span> <i className="bi bi-chevron-right"></i></a>
                <ul>
                  <li><a href="#">Deep Drop Down 1</a></li>
                  <li><a href="#">Deep Drop Down 2</a></li>
                  <li><a href="#">Deep Drop Down 3</a></li>
                  <li><a href="#">Deep Drop Down 4</a></li>
                  <li><a href="#">Deep Drop Down 5</a></li>
                </ul>
              </li>
            </ul>
          </li> */}
          <li><a className="nav-link" onClick={e => scrollTo(e, 'about')}>About</a></li>
          <li><a className="nav-link" onClick={e => scrollTo(e, 'services')}>Services</a></li>
          <li><a className="nav-link" onClick={e => scrollTo(e, 'testimonials')}>Testimonials</a></li>
          <li><a className="nav-link" onClick={e => scrollTo(e, 'team')}>Team</a></li>
          <li><a className="nav-link" onClick={e => scrollTo(e, 'partners')}>Partners</a></li>
          {/* <li><a className="nav-link" onClick={e => scrollTo(e, 'clients')}>Clients</a></li> */}
          {/* <li><a className="nav-link scrollto " href="#portfolio">Portfolio</a></li> */}
          {/* <li><a className="nav-link scrollto" href="#pricing">Pricing</a></li> */}
          <li><a className="nav-link" onClick={e => scrollTo(e, 'contact')}>Contact</a></li>
        </ul>
        <i className="bi bi-list mobile-nav-toggle"></i>
      </nav>

      <div className="header-social-links d-flex align-items-center">
        {/* <a href="#" className="twitter"><i className="bi bi-twitter"></i></a>
        <a href="#" className="facebook"><i className="bi bi-facebook"></i></a>
        <a href="#" className="instagram"><i className="bi bi-instagram"></i></a> */}
        <LinkedIn />
        {/* <a href="https://www.linkedin.com/company/gradientt" target="_blank" className="linkedin">
          <i className="bi bi-linkedin"></i>
        </a> */}
      </div>

    </div>
  </header>
)
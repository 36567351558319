import React, { FC } from "react"
import { scrollTo } from "../../helper";

export const Hero:FC = () => (
  <section id="hero">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center" data-aos="fade-up">
          <div>
            <h1>Driving App Success, Guaranteed!</h1>
            <h2>Boost Your App's Visibility, Increase User Acquisition, and Scale!</h2>
            <a onClick={e => scrollTo(e, 'about')} className="btn-get-started scrollto">Get Started</a>
          </div>
        </div>
        <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="fade-left">
          <img src="assets/img/hero-img.png" className="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </section>  
)
import React, { FC } from "react";
import { scrollTo } from "../../helper";

export const CallToAction:FC = () => (
  <section id="cta" className="cta">
    <div className="container">

      <div className="row" data-aos="zoom-in">
        <div className="col-lg-9 text-center text-lg-start">
          <h3>Supercharge Your App's Success Today - Get in Touch!</h3>
          <p>Ready to take your mobile app to new heights? Contact us now to unlock your app's full potential and drive remarkable success. Let's discuss how our tailored mobile advertising solutions can fuel your growth. Leave your contact information below, and our team of experts will reach out to you shortly. Don't miss out on the opportunity to revolutionize your app marketing strategy and achieve exceptional results!</p>
        </div>
        <div className="col-lg-3 cta-btn-container text-center">
          <a className="cta-btn align-middle" onClick={e => scrollTo(e, 'contact')}>CONTACT NOW</a>
        </div>
      </div>
    </div>
  </section>
)
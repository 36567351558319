import axios from "axios";
import React, { FC, FormEvent, useState } from "react";
import { LinkedIn } from "./LinkedIn";
import { emailPattern, scrollTo } from '../helper';


export const Footer:FC = () => {
  const [email, setEmail] = useState('');
  const [state, setState] = useState({loading: false, error: null, submitted: false});

  const handleSubmit = (e:FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const form = e.currentTarget;
    if (!form.checkValidity()) { return; }
    const url = process.env.REACT_APP_API_URL + '/subscribe';
    setState({...state, loading: true})
    axios.post(url, {email})
    .then(({data}) => {
      setState({loading: false, error: null, submitted: true});
    })
    .catch(error => {
      setState({loading: false, error: null, submitted: true});
      // setState({loading: false, error: error.message, submitted: false});
    });

  }
  return (
  <footer id="footer">
    <div className="footer-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="footer-info">
              <h3>{process.env.REACT_APP_NAME}</h3>
              {process.env.REACT_APP_NAME === 'Gradientt' ?
              (<p>
                Ahad Ha'Am st. 8 <br/>
                Tel Aviv-Yafo 6514424, Israel<br/><br/>
                <strong>Phone:</strong> +972-54-24353459<br/>
                <strong>Email:</strong> info@gradientt.com<br/>
              </p>) : 
              <p></p>}
              <div className="social-links mt-3">
                {/* <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a> */}
                {/* <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a> */}
                {/* <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a> */}
                {/* <a href="#" className="google-plus"><i className="bx bxl-skype"></i></a> */}
                {/* <a className="linkedin"><i className="bi bi-linkedin"></i></a> */}
                <LinkedIn />

              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <a onClick={e => scrollTo(e, 'root')}>Home</a></li>
              <li><i className="bx bx-chevron-right"></i> <a onClick={e => scrollTo(e, 'about')}>About</a></li>
              <li><i className="bx bx-chevron-right"></i> <a onClick={e => scrollTo(e, 'services')}>Services</a></li>
              {/* <li><i className="bx bx-chevron-right"></i> <a href="#">Terms of service</a></li> */}
              {/* <li><i className="bx bx-chevron-right"></i> <a href="#">Privacy policy</a></li> */}
            </ul>
          </div>

          <div className="col-lg-3 col-md-6 footer-links">
            {/* <h4>Our Services</h4>
            <ul>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Web Design</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Web Development</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Product Management</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Marketing</a></li>
              <li><i className="bx bx-chevron-right"></i> <a href="#">Graphic Design</a></li>
            </ul> */}
          </div>

          <div className="col-lg-4 col-md-6 footer-newsletter">
            <h4>Our Newsletter</h4>
            <p>Subscribe to our newsletter today and stay up-to-date with the latest mobile marketing trends, industry insights, and exclusive tips to supercharge your app's success.</p>
            <form onSubmit={handleSubmit} method="post">
              <input type="email" name="email" pattern={emailPattern} required
                disabled={state.loading || state.submitted} onChange={e => setEmail(e.target.value)} value={email} />
              <input type="submit" value="Subscribe" disabled={state.loading || state.submitted} />
            </form>
            {state.submitted && <div className="sent-message d-block ms-2 mt-1">Your have been successfully subscribed. Thank you!</div>}
          </div>
        </div>
      </div>
    </div>

    <div className="container">
      <div className="copyright">
        &copy; Copyright <strong><span>{process.env.REACT_APP_NAME} 2023</span></strong>. All Rights Reserved
      </div>
    </div>
  </footer>      
)}